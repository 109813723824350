<template>
  <!--<h3>Stap 6 - Uw aanvraag wordt in behandeling genomen</h3>
  <p>Dank u voor het vertrouwen in Apple Tree. 
    Uw aanvraag is succesvol in behandeling genomen. 
    U kunt hieronder de ondertekende documenten downloaden. 
    U wordt op de hoogte gehouden van de voortgang van uw aanvraag.</p>
  <p>-->
  <h3>Uw aanvraag is in behandeling</h3>
  <p>
    Uw aanvraag bij {{selectedQuote.provider}} is in behandeling genomen door Apple Tree.
    Op deze pagina kunt u uw documenten downloaden en de status van uw aanvraag volgen.
  </p>

  <SourcePaymentDossier v-if="dossier.source_charge_contact === 1" :dossier="dossier" :selectedQuote="selectedQuote" />

  <template v-if="polisFile">
    <p class="pt-3">Uw polis is opgemaakt, deze kunt u hieronder downloaden.</p>
    <button type="button" class="btn btn-primary mb-2" @click.prevent="downloadFile($event, polisFile)">
      <i class="fa fa-file-pdf"></i>&ensp;Download {{getDownloadButtonText(polisFile)}}
    </button>
  </template>
    
  <Settlement :dossier="dossier" :selectedQuote="selectedQuote" :canEdit="false" v-if="showSettlement" />

  <div class="modal fade" id="documentsModal" tabindex="-1" aria-labelledby="documentsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="modalSize ? modalSize : 'modal-lg'" :style="modalWidth ? {maxWidth: modalWidth} : {}">
        <div class="modal-content" :style="{height: (modalHeight ? modalHeight : 'auto')}">
            <div class="modal-header">
                <h4 class="modal-title" id="documentsModalLabel">
                    Mijn documenten
                </h4>
                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

              <button class="btn btn-primary mb-2 text-start d-block" @click="downloadDossierPDF">
                <i class="fa fa-file-pdf"></i>&ensp;Download Apple Tree offerte {{ dossier.contact.full_name }},  {{ formatDate(dossier.email_send_date, 'longDate') }}
              </button>
              <button class="btn btn-primary mb-2 text-start d-block" :disabled="!selectedQuote.company_quote_file_name" @click="downloadQuoteFile">
                <i class="fa fa-file-pdf"></i>&ensp;Download {{selectedQuote.provider}} offerte, {{formatDate(dossier.dossier_accepted_date, 'longDate')}}
              </button>
              <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'aanvraag_formulier')">
                <i class="fa fa-file-pdf"></i>&ensp;Download aanvraagformulier (ondertekend)
              </button>
              <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'afstandsverklaring')" 
                      v-if="dossier.lijfrente_type == null && dossier.partner && dossier.partner.last_name != null && dossier.partner.insure_partner_pension == 0 && 
                          (!dossier.pension_fund_history[0] || dossier.pension_fund_history[0].origin != 'Gouden Handdruk')">
                <i class="fa fa-file-pdf"></i>&ensp;Download afstandsverklaring (ondertekend)
              </button>
              <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'verzoek_tot_overmaken', fund.pension_company.name, fund.id)" v-for="fund in dossier.pension_fund_history" :key="fund.id">
                <i class="fa fa-file-pdf"></i>&ensp;Download verzoek tot overmaken aan {{fund.pension_company.name}} (ondertekend)
              </button>
              <button v-if="dossier.invoice" class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'factuur')">
                <i class="fa fa-file-pdf"></i>&ensp;Download factuur {{dossier.invoice.invoice_number}}
              </button>
              <button v-if="polisFile" type="button" class="btn btn-primary mb-2 d-block" @click.prevent="downloadFile($event, polisFile)">
                <i class="fa fa-file-pdf"></i>&ensp;Download {{getDownloadButtonText(polisFile)}}
              </button>
            <br />
            <h5 class="fw-bold">Aangeleverde documenten</h5>

            <div v-for="file in files" :key="file.id">
              <template v-if="file.content_type != 'full_document' && file.content_type != 'polis'">
                <button type="button" class="btn btn-primary mb-2" @click.prevent="downloadFile($event, file)">
                  <i class="fa fa-file"></i>&ensp;Download {{getDownloadButtonText(file)}}
                </button>
              </template>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dossierservice from '@/services/contact/DossierService'
  import Settlement from "@/components/admin/dossier/Settlement"
  import SourcePaymentDossier from '@/components/dossier/SourcePaymentDossier'

  export default {
    name: 'DocumentsOverviewDossier',
    props: {
      dossier: {}
    },
    components: {
      SourcePaymentDossier,
      Settlement
    },
    data() {
      return {
        files: [],
        polisFile: null,
        fileDownloadNames: {
          'bankIdentification': 'bankidentificatie',
          'identification': 'legitimatiebewijs_aanvrager',
          'identificationPartner': 'legitimatiebewijs_partner',
          'oldPolis': 'oude_polissen',
          'polis': 'polis'
        },
        showSettlement: false
      }
    },
    computed: {
      selectedQuote() {
        return this.dossier.quotes.filter(function (q) {
          return q.selected
        })[0]
      }
    },
    methods: {
      downloadFile(e, file) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice.downloadFile(this.dossier.id, file.id).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, this.fileDownloadNames[file.content_type], file.file_type));
          
          document.body.appendChild(link);
          link.click();
          this.setButtonLoadingIcon(e.target, false);
        }).catch(error => {
          this.$toast.error('Error while downloading file.');
          console.log(error);
        })
      },
      downloadDossierPDF(e) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, 'offerte')
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const d = this.dossier;
            const filename = this.setDownloadFileName(d.dossier_number, d.contact.full_name, 'offerte van Apple Tree');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadQuoteFile(e) {
        const selquote = this.selectedQuote;
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .downloadQuoteFile(selquote.id)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const filename = selquote.company_quote_file_name.includes('.pdf') ? selquote.company_quote_file_name  : (selquote.company_quote_file_name + ".pdf");
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadGeneratedPDF(e, type, fundName, fundId = '') {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, type, fundId)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            let documentType = type;
            if(fundName){
              documentType = 'verzoek tot overmaken ' + fundName;
            }
            
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, documentType));
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      getDownloadButtonText(file){
        if(file.content_type == 'bankIdentification') return 'bankidentificatie';
        if(file.content_type == 'identification') return 'legitimatiebewijs aanvrager (' + this.dossier.contact.full_name + ')';
        if(file.content_type == 'identificationPartner') return 'legitimatiebewijs partner (' + (this.dossier.partner ? this.dossier.partner.full_name : null) + ')';
        if(file.content_type == 'polis') return 'polis ' + this.selectedQuote.provider;
        return file.name;
      }
    },
    mounted(){
      this.files = this.dossier.files;
      this.files.sort((a, b) => {return (a.content_type.length % 8) < (b.content_type.length % 8)});
      this.polisFile = this.files.find(f => f.content_type == 'polis');
      if((this.dossier.source.code == "befrank" || this.dossier.source.code == "BFR") && this.dossier.created_at > "2024-06-01"){
        this.showSettlement = true;
      }
    }
  }
</script>