<template>
  <div v-show="visible">
    <h3>Stap 5 - Offerte online ondertekenen</h3>
    <p class="">
      Uw aanvraag is bijna gereed. Hieronder kunt u de documenten welke online worden ondertekend nogmaals downloaden. 
      Door in onderstaande velden uw handtekening(en) te plaatsen worden deze documenten voor u digitaal ondertekend. 
      In de volgende stap kunt u de ondertekende documenten downloaden.
      <br>
    </p>

    <p>
      <button class="btn btn-primary mb-2  text-start" @click="downloadDossierPDF">
        <i class="fa fa-file-pdf"></i>&ensp;Download Apple Tree offerte {{ dossier.contact.full_name }}, {{ formatDate(dossier.email_send_date, 'longDate') }}
      </button><br>
      <button class="btn btn-primary mb-2 text-start" :disabled="!selectedQuote.company_quote_file_name" @click="downloadQuoteFile">
        <i class="fa fa-file-pdf"></i>&ensp;Download {{selectedQuote.provider}} offerte, {{formatDate(new Date(), 'longDate')}}
      </button>
      <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'aanvraag_formulier')">
        <i class="fa fa-file-pdf"></i>&ensp;Download aanvraagformulier (klaar voor ondertekening)
      </button>
      <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'afstandsverklaring')"
              v-if="dossier.lijfrente_type == null && dossier.partner && dossier.partner.last_name != null && dossier.partner.insure_partner_pension == 0 && 
                  (!dossier.pension_fund_history[0] || dossier.pension_fund_history[0].origin != 'Gouden Handdruk')">
        <i class="fa fa-file-pdf"></i>&ensp;Download afstandsverklaring (klaar voor ondertekening)
      </button>
      <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'verzoek_tot_overmaken', fund.pension_company.name, fund.id)" v-for="fund in dossier.pension_fund_history" :key="fund.id">
        <i class="fa fa-file-pdf"></i>&ensp;Download verzoek tot overmaken aan {{fund.pension_company.name}} (klaar voor ondertekening)
      </button>
    </p>

    <br />
    <div class="row">
      <p> </p>
      <div>
        <h5>
          Handtekening:
          {{ dossier.contact.addressed_name }}
          {{ formatDate(dossier.contact.birth_date, "shortDate") }}
        </h5>
        De handtekening moet zoveel mogelijk overeenkomen met uw handtekening uit het legitimatiebewijs.
        <br><br>
        <div class="d-flex">
          <div class="perfect-signature" id="main-sig">
            <VPerfectSignature :stroke-options="strokeOptions" width="400" height="200" ref="signaturePad" @onBegin="startTimer('main')" @onEnd="signDossier('main')" />
          </div>
          <button class="btn btn-warning ms-2" style="height: 38px" @click="clear('main')">opnieuw</button>
          <div class="clear"></div>
        </div>
<small><strong>Tip:</strong> U kunt dit het beste doen door in te loggen op mijn Apple Tree via uw smartphone of tablet dan de handtekening met uw vinger te zetten. (dit kan ook nadat u de gegevens heeft ingevuld en/of upgeload).</small><br>
        <div class="invalid-feedback"
          v-bind:style="{ display: validate && !mainSignatureValid ? 'block' : 'none' }">Geef uw
          handtekening op.</div>
        <br>
      </div>

      <template v-if="dossier.has_partner && dossier.partner && dossier.lijfrente_type != 'bancair'">
        <div>
          <h5>
            Handtekening:
            {{ dossier.partner.addressed_name }}
            {{ formatDate(dossier.partner.birth_date, "shortDate")}}
          </h5>
          De handtekening moet zoveel mogelijk overeenkomen met uw handtekening uit het legitimatiebewijs.  
          <br><br>
         <div class="d-flex">
            <div  class="perfect-signature" id="partner-sig">
              <VPerfectSignature :stroke-options="strokeOptions" width="400" height="200" ref="signaturePadPartner"
                @onBegin="startTimer('partner')" @onEnd="signDossier('partner')" />
            </div>
            <button class="btn btn-warning ms-2" style="height: 38px" @click="clear('partner')">opnieuw</button>
            <div class="clear"></div>
          </div>
<small><strong>Tip:</strong> U kunt dit het beste doen door in te loggen op mijn Apple Tree via uw smartphone of tablet dan de handtekening met uw vinger te zetten. (dit kan ook nadat u de gegevens heeft ingevuld en/of upgeload).</small><br>
          <div class="invalid-feedback"
            v-bind:style="{ display: validate && !partnerSignatureValid ? 'block' : 'none' }">
            Geef uw handtekening op.</div>
          <br>
        </div>
      </template>
      <br>
   </div>

    <button class="btn btn-secondary" @click="this.$router.push('documents')" :disabled="shouldDisable">&larr;&ensp; Vorige stap</button>
    <button class="btn btn-success float-end" @click="acceptDossier" :disabled="shouldDisable || processingAcceptance">Aanvraag afronden &ensp;<i class="fa fa-arrow-right"></i></button>
  </div>

  <div class="modal show d-block" v-if="shouldShowDialog" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <dialog open="" class="modal-content p-0">
        <div class="modal-body">
          <p>Heeft u alle velden correct ingevuld en is de door u ingevoerde handtekening gelijk aan die op uw legitimatie?<br />Dien dan uw aanvraag definitief in.</p>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" required v-model="acceptCosts" :true-value="1" :false-value="0"/>
            <label class="form-check-label" for="flexCheckChecked2">
              <template v-if="dossier.source_charge_contact">
                Ik ga akkoord met de offerte van {{ selectedQuote.provider }} en de eenmalige afhandelingskosten van {{ formatCurrency(dossier.source_price ? dossier.source_price : dossier.source.price) }} van Apple Tree.
              </template>
              <template v-else>
                <!--Ik ga akkoord met de offerte van {{ selectedQuote.provider }} en de kosten voor de dienstverlening van Apple Tree a {{ formatCurrency(dossier.source_price) }}.-->
                Ik ga akkoord met de offerte van {{ selectedQuote.provider }}.
              </template>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="acceptDossierDefinitive" :disabled="!acceptCosts">Aanvraag indienen</button>
          <button type="button" class="btn btn-secondary" @click="dismissModal">Annuleren</button>
        </div>
      </dialog>
    </div>
  </div>
</template>

<script>
  import dossierservice from "@/services/contact/DossierService";
  import VPerfectSignature from "v-perfect-signature";

  export default {
    name: "signDossier",
    props: {
      dossier: {},
      visible: Boolean  // Is nodig omdat het anders fout gaat bij VPerfectSignature als je een stap terug gaat
    },
    data: () => ({
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      acceptCosts: false,
      shouldShowDialog: false,
      mainSignatureValid: true,
      partnerSignatureValid: true,
      validate: false,
      startTimePartner: 0,
      startTimeMain: 0,
      processingAcceptance: false
    }),
    computed: {
      selectedQuote() {
        return this.dossier.quotes.filter(function (q) {
          return q.selected
        })[0]
      },
      shouldDisable(){
          return this.dossier.max_step_id >= 23;
      }
    },
    components: {
      VPerfectSignature,
    },
    methods: {
      clear(person) {
        if(this.shouldDisable) return;
        if (person == "partner") {
          this.$refs.signaturePadPartner.clear();
        } else {
          this.$refs.signaturePad.clear();
        }
      },
      startTimer(signee) {
        let isPartner = signee == "partner";
        if (isPartner) {
          this.startTimePartner = new Date().valueOf()
        } else {
          this.startTimeMain = new Date().valueOf()
        }
      },
      signDossier(signee) {
        let isPartner = signee == "partner";

        if(this.shouldDisable) {
          if(isPartner){
            this.$refs.signaturePadPartner.clear();
            let signaturePartner = this.dossier.signature_partner;
            this.$refs.signaturePadPartner.fromDataURL(signaturePartner);
          }
          else{
            this.$refs.signaturePad.clear();
            let signatureMain = this.dossier.signature;
            this.$refs.signaturePad.fromDataURL(signatureMain);
          }
          return;
        }

        let dataURL = document.querySelector('#' + (isPartner ? 'partner' : 'main') + '-sig canvas').toDataURL();
        let cropDataURL = this.cropImage(document.querySelector('#' + (isPartner ? 'partner' : 'main') + '-sig canvas'));
        
        let timeNow = new Date().valueOf();
        if (isPartner) {
          if (this.startTimePartner+100 > timeNow || !this.isSignatureValid('signaturePadPartner')){
            dataURL = false
            console.log('partner empty');
          }
        } else {
          if (this.startTimeMain+100 > timeNow || !this.isSignatureValid('signaturePadMain')){
            dataURL = false
            console.log('main empty');
          }
        }

        if (dataURL) {
          dossierservice
            .signDossier(this.dossier.id, dataURL, isPartner, cropDataURL)
            .then((response) => {
              console.log("response", response.data); // create notification-toaster for user
              sessionStorage.setItem(isPartner ? 'signature-partner' : 'signature-main', dataURL);
            })
            .catch((error) => {
              this.$toast.error("Error while storing data");
            });
            this.testDataURL = dataURL;
        }
      },
      acceptDossier() {
        this.validate = true;

        this.mainSignatureValid = this.isSignatureValid('signaturePad');
        if (this.dossier.partner && this.dossier.has_partner && this.dossier.lijfrente_type != 'bancair') this.partnerSignatureValid = this.isSignatureValid('signaturePadPartner');
        let valid = this.mainSignatureValid && this.partnerSignatureValid;

        if (!valid) {
          this.processingAcceptance = false
          return false
        }

        //Show confirm text
        this.shouldShowDialog = true;
      },

      acceptDossierDefinitive(){
        if (!this.acceptCosts){
          return;
        }
        this.shouldShowDialog = false;
        this.processingAcceptance = true;
        dossierservice
          .acceptDossier(this.$parent.dossier.id, this.$parent.dossier.dossier_step_id)
          .then((response) => {
            this.processingAcceptance = false
            this.$parent.setStep(response.data.dossier_step_id)
            this.$router.push("sourcePayment")
          })
          .catch((error) => {
            this.processingAcceptance = false
            this.$toast.error("Error while storing data")
          });
      },

      dismissModal(){
        this.shouldShowDialog = false;
      },

      downloadDossierPDF(e) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, 'offerte')
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const d = this.dossier;
            const filename = this.setDownloadFileName(d.dossier_number, d.contact.full_name, 'offerte van Apple Tree');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadGeneratedPDF(e, type, fundName, fundId = '') {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, type, fundId)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            let documentType = type;
            if(fundName){
              documentType = 'verzoek tot overmaken ' + fundName;
            }
            
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, documentType));
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadQuoteFile(e) {
        const selquote = this.selectedQuote;
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .downloadQuoteFile(selquote.id)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const filename = selquote.company_quote_file_name.includes('.pdf') ? selquote.company_quote_file_name  : (selquote.company_quote_file_name + ".pdf");
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      cropImage(canvas){
        const pixelData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data;
        let minX = canvas.width;
        let minY = canvas.height;
        let maxX = 0;
        let maxY = 0;

        // Iterate over the pixels and find the bounding box of the non-white pixels
        for (let x = 0; x < canvas.width; x++) {
          for (let y = 0; y < canvas.height; y++) {
            const index = (y * canvas.width + x) * 4;
            const r = pixelData[index];
            const g = pixelData[index + 1];
            const b = pixelData[index + 2];
            const a = pixelData[index + 3];
            if (a != 0) {
              if (x < minX) {
                minX = x;
              }
              if (y < minY) {
                minY = y;
              }
              if (x > maxX) {
                maxX = x;
              }
              if (y > maxY) {
                maxY = y;
              }
            }
          }
        }
        const width = maxX - minX + 2;
        const height = maxY - minY + 2;
        const signatureCanvas = document.createElement('canvas');
        signatureCanvas.width = width;
        signatureCanvas.height = height;
        signatureCanvas.style.width = width + 'px';
        signatureCanvas.style.height = height + 'px';
        signatureCanvas.getContext('2d').drawImage(canvas, minX, minY, width, height, 1, 1, width - 1, height - 1);
        return signatureCanvas.toDataURL();
      },
      isSignatureValid(pad){
        const canvas = document.querySelector('#' + (pad == 'signaturePadPartner' ? 'partner' : 'main') + '-sig canvas');
        const pixelData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data;

        let nonwhite = 0;
        const minNonwhite = 50;

        // Iterate over the pixels and count non-white pixels
        for (let x = 0; x < canvas.width; x+=2) {
          for (let y = 0; y < canvas.height; y+=2) {
            const index = (y * canvas.width + x) * 4;
            const a = pixelData[index + 3];
            if (a > 64) {
              nonwhite++;
            }
          }
        }
        console.log("Score: " + nonwhite);
        return nonwhite >= minNonwhite;
      },
    },
    mounted() {
      let signatureMain = this.dossier.signature;
      if(sessionStorage.getItem('signature-main')){
        signatureMain = sessionStorage.getItem('signature-main');
      }
      if (signatureMain) {
        this.$refs.signaturePad.fromDataURL(signatureMain);
      }
      if (this.dossier.dossier_accepted_date) {
        this.acceptCosts = 1;
      }
      if (this.dossier.partner) {
        let signaturePartner = this.dossier.signature_partner;
        if(sessionStorage.getItem('signature-partner')){
          signaturePartner = sessionStorage.getItem('signature-partner');
        }
        if (signaturePartner) {
          this.$refs.signaturePadPartner.fromDataURL(signaturePartner);
        }

      }
      this.$nextTick(() => {
        const canvasses = document.querySelectorAll('canvas');
        canvasses.forEach(canvas => {
          canvas.width = 400;
          canvas.height = 200;
        })
      })
    },
  };
</script>

<style lang="css" scoped>
  .perfect-signature {
    border: 1px solid #555555;
    width: 400px;
    height: 200px;
  }
  canvas:not(#testcanvas) {
    width: 400px !important;
    height: 200px !important;
  }
  .modal{
    background-color: rgba(1,1,1,0.5)
  }
</style>